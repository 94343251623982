import Base from './base.js';
var Utils = {
  stripTags:function(string){
    return string.replace(/(<([^>]+)>)/ig,'');
  },
  infoDialog:function(msg){
    Base.app.dialog.alert(`<div>${msg}</div>`,'<i class="f7-icons icon text-color-green">exclamationmark_bubble_fill</i> THÔNG BÁO');
    return false;
  },
  errorDialog:function(msg){
    Base.app.dialog.alert(`<div>${msg}</div>`,'<i class="f7-icons icon text-color-orange">exclamationmark_triangle_fill</i> CÓ LỖI');
    return false;
  },
  notiInfo:(message)=>{
    Base.app.toast.create({
      text: message,
      closeTimeout:2000
    }).open();
  },
  notiWarning:(message)=>{
    Base.app.notification.create({
      title: '',
      text: message,
      closeOnClick:true,
      closeTimeout:2000
    }).open();
  },
  notiError:(message)=>{
    Base.app.notification.create({
      title: '',
      text: message,
      closeOnClick:true,
      closeTimeout:2000
    }).open();
  },
  getHtmlSelectForFilter:function(params){
    let select = `<select name="${params.name}">`;
    select += `<option value="">-</option>`;
    for(var key in params.values) {
      let selected = (params.selected != undefined && params.selected != null && params.selected == key) ? 'selected' : '';
      select += `<option value="${key}" ${selected}>${params.values[key]}</option>`;
    }
    select += '</select>';
    return select;
  },
  getHtmlSelect:function(params){
    let select = `<select name="${params.name}">`;
    for(var key in params.values) {
      let selected = (params.selected != undefined && params.selected != null && params.selected == key) ? 'selected' : '';
      select += `<option value="${key}" ${selected}>${params.values[key]}</option>`;
    }
    select += '</select>';
    return select;
  },
  daysBetween:function( date1, date2 ) {
    //Get 1 day in milliseconds
    var one_day=1000*60*60*24;

    // Convert both dates to milliseconds
    var date1_ms = date1.getTime();
    var date2_ms = date2.getTime();

    // Calculate the difference in milliseconds
    var difference_ms = date2_ms - date1_ms;

    // Convert back to days and return
    return Math.round(difference_ms/one_day);
  },
  vietDecode:(alias)=>{
    var str = alias;
    str = str.toLowerCase();
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g,"a");
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g,"e");
    str = str.replace(/ì|í|ị|ỉ|ĩ/g,"i");
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g,"o");
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g,"u");
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g,"y");
    str = str.replace(/đ/g,"d");
    str = str.replace(/!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|_|`|-|{|}|\||\\/g," ");
    str = str.replace(/ + /g," ");
    str = str.replace(/ /g,"-");
    str = str.trim();
    return str;
  },
  parseInt:(n)=>{
    var num;
    if(n == 0 || n == '' || n == null || n == "0")
      return 0;

    num = n.toString().replace(/\./g,'');
    num = parseInt(num);
    if(isNaN(num))
      return 0;
    return num;
  },
  num2Str:(n)=>{
    if(n == '' || n == null || n == undefined)
      return 0;
    return Number(n).toLocaleString(Base.language);
  },
  str2Num:(n)=>{
    if(n == '' || n == null || n == undefined)
      return 0;
    return Utils.parseInt(n);
  },
  percentage:(a,b)=>{
    var x = Utils.str2Num(a);
    var y = Utils.str2Num(b);
    return ((x/y)*100).toFixed(0);
  },
  roundUp:function(number,near){
    if(number%near===0) return number;
      return  parseInt(number / near) * near+near;
  },
  removeVietnameseTones:(str)=>{
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g,"a"); 
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g,"e"); 
    str = str.replace(/ì|í|ị|ỉ|ĩ/g,"i"); 
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g,"o"); 
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g,"u"); 
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g,"y"); 
    str = str.replace(/đ/g,"d");
    str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A");
    str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E");
    str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
    str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O");
    str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U");
    str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y");
    str = str.replace(/Đ/g, "D");
    // Some system encode vietnamese combining accent as individual utf-8 characters
    // Một vài bộ encode coi các dấu mũ, dấu chữ như một kí tự riêng biệt nên thêm hai dòng này
    str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ""); // ̀ ́ ̃ ̉ ̣  huyền, sắc, ngã, hỏi, nặng
    str = str.replace(/\u02C6|\u0306|\u031B/g, ""); // ˆ ̆ ̛  Â, Ê, Ă, Ơ, Ư
    // Remove extra spaces
    // Bỏ các khoảng trắng liền nhau
    str = str.replace(/ + /g," ");
    str = str.trim();
    // Remove punctuations
    // Bỏ dấu câu, kí tự đặc biệt
    str = str.replace(/!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|_|`|-|{|}|\||\\/g," ");
    return str;
  }
}

export default Utils
