import Framework7 from 'framework7/bundle';
import $ from 'dom7';
import Utils from './utils.js';
import moment from 'moment';
var Base = {
  app:null,
  token:null,
  tokenKey:'empapp-token',
  user:null,
  userKey:'empapp-user',
  apiUrl:'',
  language:'vi-VN',
  apiEndPoint: (process.env.NODE_ENV === 'development') ? 'https://haxinh.com/api-dev/?app=emp&' : 'https://haxinh.com/api-v1/?app=emp&',
  uploadURL:'https://haxinh.com/uploads',
  uploadCCMURL:'https://haxinh.com/uploads/chamcongmuon',
  dateTimeFormat:'D/M/Y HH:mm',
  dateFormat:'D/M/Y',
  draftKey:null,
  branches:[],
  historyItems:new Map(),
  donHangDatStatus:{
    0:'Chưa xử lý',
    1:'Đã xử lý'
  },
  branchesLoaded:false,
  anonymousLabel:'Ẩn danh',
  initdata:{},
  init: async function(app){

    let self = this;
    self.app = app;

    //GET BRANCHES
    var rs = await Base.api('App','initdataForEmp',{});
    self.branches = rs.branches;
    self.branchesLoaded = true;
    self.initdata = rs;
    self.initdataLoaded = true;

    let token = localStorage.getItem(self.tokenKey);
    if(token == null || token == undefined) {
      self.showMenuBarsButton(false);
    } else {
      try {
        self.token = localStorage.getItem(self.tokenKey);
        self.user = JSON.parse(localStorage.getItem(self.userKey));
        self.showMenuBarsButton(true);
        self.checkMessage();
      } catch(err) {
        console.log(err);
      }
    }
  },
  checkMessage:async function(){
    let rs = await Base.api('Notification', 'getUnread', {});
    let unread = Utils.str2Num(rs.count);
    if( unread > 0 ) {
      $('#menu-message-icon').html('<i class="icon f7-icons">envelope<span class="badge color-red">'+unread+'</span></i>');
    } else {
      $('#menu-message-icon').html('<i class="icon f7-icons">envelope</i>');
    }
  },
  showMenuBarsButton:function(flag){
    let self = this;
    if(flag) {
      $('#menu-bars').removeClass('display-none');
      if(self.user.is_manager === 1) {
        $('#for-managers-only').removeClass('display-none');
      } else {
        $('#for-managers-only').addClass('display-none');
      }
    } else {
      $('#menu-bars').addClass('display-none');
      $('#for-managers-only').addClass('display-none');
    }
  },
  call:async (params)=>{
    return await Base.api('App','call', params);
  },
  api:async function(mdl,action,postdata){
    let data = postdata;
    data.token = localStorage.getItem(Base.tokenKey);
    try {
      let rs = await Framework7.request({
        url:Base.apiEndPoint+`mdl=${mdl}&action=${action}`,
        method:'post',
        dataType:'json',
        data:data
      });
      return rs.data;
    } catch(err) {
      console.log(err);
      return {};
    }
  },
  loginPopup:function(content){
    let self = this;
    this.app.popup.create({
      content:content,
      on:{
        open:function(popup){
          let doLogin = async function(){
            let phone = $(popup.el).find('input[name=phone]').val();
            let password = $(popup.el).find('input[name=password]').val();
            var rs = await self.api('App','login',{phone, password});
            if(rs.result == 'success') {
              localStorage.setItem(self.tokenKey, rs.token);
              localStorage.setItem(self.userKey, JSON.stringify(rs.user));
              self.token = rs.token;
              self.user = rs.user;
              popup.close();
              self.showMenuBarsButton(true);
            } else {
              self.app.dialog.alert(rs.message);
            }
          }

          $(popup.el).find('a.login-button').click(function(){
            doLogin();
          })
        }
      }
    }).open();
  },
  logout:function(){
    let self = this;
    localStorage.removeItem(self.tokenKey);
    localStorage.removeItem(self.userKey);
    self.token = null;
    self.user = null;
    self.showMenuBarsButton(false);
    self.app.views.main.router.navigate('/');
  },
  profilePopup:function(content){
    let self = this;
    self.app.popup.create({
      content:content,
      on:{
        open:function(popup){
          let $el = $(popup.el);
          $el.find('input[name=name').val(self.user.name);
          $el.find('input[name=phone').val(self.user.phone);

          let a = self.branches.find(item=>item.id==self.user.default_branch);
          let b = (a)?a.name:'- Không cố định -';

          $el.find('input[name=branch').val(b);

          $el.find('.logout-button').click(function(){
            popup.close();
            self.logout();
          })
          $el.find('.change-password-button').click(async function(){
            let a = $el.find('input[name=current-password]').val().trim();
            let b = $el.find('input[name=new-password]').val().trim();
            let c = $el.find('input[name=confirm-password]').val().trim();

            if(a==''||b==''||c=='') {
              Utils.notiError('Không được để mật khẩu trắng');
              return;
            }

            if(b.length < 8) {
              Utils.notiError('Mật khẩu cần ít nhất 8 ký tự');
              return;
            }

            if(b != c) {
              Utils.notiError('Mật khẩu xác nhận không khớp');
              return;
            }

            let rs = await Base.api('Account','changePassword',{a,b});
            if(rs.result == 'error') {
              Utils.notiError('Mật khẩu hiện thời không chính xác');
              return;
            }

            if(rs.result == 'success') {
              Utils.notiInfo('Thay đổi mật khẩu thành công');
              popup.close();
            }
          })
        }
      }
    }).open();
  },
  profile:async function(){
    let self = this;
    let content = '';
    let token = localStorage.getItem(self.tokenKey);
    if(token == null || token == undefined) {
      self.loginPopup($('#login-popup').html());
    } else {
      Base.app.dialog.preloader('Kiểm tra thông tin...');
      var rs = await Base.api('App','checkToken',{});
      if(rs.result == 'success') {
        Base.app.dialog.close();
        Base.token = localStorage.getItem(Base.tokenKey);
        Base.user = JSON.parse(localStorage.getItem(Base.userKey));
        self.profilePopup($('#profile-popup').html());
      } else {
        Base.app.dialog.close();
        Base.app.dialog.alert('Đăng nhập tự động thất bại. Vui lòng đăng nhập lại.');
        self.logout();
        self.loginPopup($('#login-popup').html());
      }
    }
  },
  checkValidNewline:function(dataRow){
    let valid = true;
    let dataElements = dataRow.split(';');
    if(dataElements.length != 3) {
      Base.app.dialog.alert('Dữ liệu không đúng định dạng. Mỗi dòng cần 2 dấu ;');
      valid = false;
      return valid;
    }
    let code = dataElements[0].trim();
    let qty = dataElements[1].trim();
    let note = dataElements[2].trim();

    if(code.length != 4 || code == '' || Utils.str2Num(code) == 0) {
      Base.app.dialog.alert('Mã sản phẩm cần đúng 4 số');
      valid = false;
      return valid;
    }
    qty = Utils.str2Num(qty);
    if(qty == 0) {
      Base.app.dialog.alert('Số lượng không hợp lệ');
      valid = false;
      return valid;
    }
    return {code,qty,note};
  },
  // upload:async function(mdl, action, formdata){
  //   formdata.append('token', localStorage.getItem(Base.tokenKey));
  //   try {
  //     let rs = await $.ajax({
  //       url:Base.apiUrl(mdl, action),
  //       type:'post',
  //       dataType:'json',
  //       processData: false,
  //       contentType: false,
  //       data:formdata,
  //     });
  //     return rs;
  //   } catch(err) {
  //     console.log(err);
  //     return {};
  //   }
  // },
  getXuatNhapTonData:async ()=>{
    const rs = await Base.api('XuatNhapTon','getData',{});
    if(rs.result === 'success') {
      Base.baocao_chuyenban = rs.rows;
      return rs.rows;
    } else {
      $f7.dialog.alert('Đã xảy ra lỗi! :(');
    }
    return null;
  },
  canhbaoNhatHang:async(code)=>{
    let baocaoRows = Base.baocao_chuyenban;

    if(baocaoRows === null || baocaoRows === undefined)
      baocaoRows = await Base.getXuatNhapTonData();

    //Tìm xem mã này có đang tồn tại cơ sở mình không?
    let row = baocaoRows.find(row=>row.code.substring(4,8) === code && row.id_branch === Base.user.default_branch);

    if(row) {
      //Nội dung cảnh báo
      const text = `
        <div>${row.code} - ${row.name}</div>
        <div>Chuyển lên: ${row.qty_chuyen} ngày ${moment(row.ngay_chuyen).format(Base.dateFormat)}</div>
        <div>Đã bán: ${row.qty_ban}</div>
      `;

      //Hiển thị cảnh báo
      Base.app.notification.create({
        icon:'<i class="f7-icons icon text-color-orange">exclamationmark_triangle_fill</i>',
        title:'Lưu ý',
        text:text,
        closeButton:true,
        closeOnClick:true,
      }).open();
    }
  }
};

export default Base;
