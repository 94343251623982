/** @jsx $jsx */
import { $jsx } from 'framework7';
import $ from 'dom7';
import Base from './js/base.js';
export default (function (props, ctx) {
  var profile = function profile() {
    Base.profile();
  };

  return function ($ctx) {
      var $ = $ctx.$;
      var $h = $ctx.$h;
      var $root = $ctx.$root;
      var $f7 = $ctx.$f7;
      var $f7route = $ctx.$f7route;
      var $f7router = $ctx.$f7router;
      var $theme = $ctx.$theme;
      var $update = $ctx.$update;
      var $store = $ctx.$store;

      return $h`
  <div id="app" class="theme-dark color-theme-orange">

    <div class="theme-dark panel panel-left panel-cover panel-init" style="background-color: #121212">
      <div class="list no-hairlines">
        <ul>
          <li>
            <a href="/" class="item-link panel-close">
              <div class="item-content">
                <div class="item-media"><i class="icon f7-icons">calendar</i></div>
                <div class="item-inner">Lịch làm việc</div>
              </div>
            </a>
          </li>
          <li>
            <a href="/dayoff/" class="item-link panel-close">
              <div class="item-content">
                <div class="item-media"><i class="icon f7-icons">equal_square</i></div>
                <div class="item-inner">Lịch nghỉ</div>
              </div>
            </a>
          </li>
          <li>
            <a href="/report-accident/" class="item-link panel-close">
              <div class="item-content">
                <div class="item-media"><i class="icon f7-icons">delete_right</i></div>
                <div class="item-inner">Chấm công muộn</div>
              </div>
            </a>
          </li>
          <li>
            <a href="/customer-search/" class="item-link panel-close">
              <div class="item-content">
                <div class="item-media"><i class="icon f7-icons">person_2_square_stack</i></div>
                <div class="item-inner">Lịch sử khách</div>
              </div>
            </a>
          </li>
          <li>
            <a href="/kho-thoc/" class="item-link panel-close">
              <div class="item-content">
                <div class="item-media"><i class="icon f7-icons">poultry_leg</i></div>
                <div class="item-inner">Kho thóc</div>
              </div>
            </a>
          </li>
          <!-- 
          <li>
            <a href="/income/" class="item-link panel-close">
              <div class="item-content">
                <div class="item-media"><i class="icon f7-icons">money_dollar_circle</i></div>
                <div class="item-inner">Thu nhập</div>
              </div>
            </a>
          </li>
           -->
          <!-- <li>
            <a href="/faq/" class="item-link panel-close">
              <div class="item-content">
                <div class="item-media"><i class="icon f7-icons">question_circle</i></div>
                <div class="item-inner">Cẩm nang bán hàng</div>
              </div>
            </a>
          </li> -->
          <!-- <li>
            <a href="/vipham-noiquy/" class="item-link panel-close">
              <div class="item-content">
                <div class="item-media"><i class="icon f7-icons">exclamationmark_triangle_fill</i></div>
                <div class="item-inner">Nhắc nhở</div>
              </div>
            </a>
          </li> -->
          <li>
            <a href="/cross-review/" class="item-link panel-close">
              <div class="item-content">
                <div class="item-media"><i class="icon f7-icons">person_2_fill</i></div>
                <div class="item-inner">Đánh giá chéo</div>
              </div>
            </a>
          </li>
          <!-- <li>
            <a href="/comment/" class="item-link panel-close">
              <div class="item-content">
                <div class="item-media"><i class="icon f7-icons">ear</i></div>
                <div class="item-inner">Góp ý</div>
              </div>
            </a>
          </li>
          <li>
            <a href="/huyhieu-home/" class="item-link panel-close">
              <div class="item-content">
                <div class="item-media"><i class="icon f7-icons">checkmark_seal</i></div>
                <div class="item-inner">Khen thưởng</div>
              </div>
            </a>
          </li> -->
          <li>
            <a href="/notification/" class="item-link panel-close">
              <div class="item-content">
                <div class="item-media" id="menu-message-icon"><i class="icon f7-icons">envelope</i></div>
                <div class="item-inner">Thông báo</div>
              </div>
            </a>
          </li>
          <li>
            <a href="/update/" class="item-link panel-close">
              <div class="item-content">
                <div class="item-media"><i class="icon f7-icons">gear</i></div>
                <div class="item-inner">Cập nhật</div>
              </div>
            </a>
          </li>
        </ul>
      </div>
      <div class="list no-hairlines display-none" id="for-managers-only">
        <ul>
          <!-- <li>
            <a href="/create/" class="item-link panel-close">
              <div class="item-content">
                <div class="item-media"><i class="icon f7-icons">plus_app</i></div>
                <div class="item-inner">Tạo đơn nhặt hàng</div>
              </div>
            </a>
          </li>
          <li>
            <a href="/search/" class="item-link panel-close">
              <div class="item-content">
                <div class="item-media"><i class="icon f7-icons">doc_text_search</i></div>
                <div class="item-inner">Tra cứu</div>
              </div>
            </a>
          </li>
          <li>
            <a href="/draft/" class="item-link panel-close">
              <div class="item-content">
                <div class="item-media"><i class="icon f7-icons">doc_plaintext</i></div>
                <div class="item-inner">Đơn nháp</div>
              </div>
            </a>
          </li>
          <li>
            <a href="/lack/" class="item-link panel-close">
              <div class="item-content">
                <div class="item-media"><i class="icon f7-icons">doc_text</i></div>
                <div class="item-inner">Hàng thiếu</div>
              </div>
            </a>
          </li>
          <li>
            <a href="/history/" class="item-link panel-close">
              <div class="item-content">
                <div class="item-media"><i class="icon f7-icons">square_favorites</i></div>
                <div class="item-inner">Lịch sử đơn nhặt</div>
              </div>
            </a>
          </li> -->
          <!-- <li>
            <a href="/chuyen-ban-ton/" class="item-link panel-close">
              <div class="item-content">
                <div class="item-media"><i class="icon f7-icons">rectangle_3_offgrid</i></div>
                <div class="item-inner">Báo cáo chuyển/bán</div>
              </div>
            </a>
          </li> -->
        </ul>
      </div>
    </div>

    <!-- Your main view, should have "view-main" class -->
    <div class="view view-main view-init safe-areas" data-url="/">
      <div class="navbar">
        <div class="navbar-bg"></div>
        <div class="navbar-inner">
          <div class="left"><a href="#" data-panel=".panel-left" class="panel-open display-none" id="menu-bars"><i class="f7-icons icon">bars</i></a></div>
          <div class="title sliding" id="app-title"></div>
          <div class="right"><a href="#" @click=${profile} id="login-button"><i class="f7-icons icon">person_circle</i></a></div>
        </div>
      </div>
    </div>


    <!-- Popup -->
    <div id="profile-popup">
      <div class="popup profile-popup">
        <div class="view">
          <div class="page">
            <div class="navbar">
              <div class="navbar-bg"></div>
              <div class="navbar-inner">
                <div class="title">Thông tin tài khoản</div>
                <div class="right">
                  <a href="#" class="link popup-close"><i class="icon f7-icons">multiply</i></a>
                </div>
              </div>
            </div>
            <div class="toolbar tabbar toolbar-bottom">
              <div class="toolbar-inner">
                <a href="#" class="button button-fill color-red logout-button">Thoát</a>
              </div>
            </div>
            <div class="page-content">
              <div class="list no-hairlines-md no-margin">
                <ul>
                  <li>
                    <div class="item-content item-input">
                      <div class="item-inner">
                        <div class="item-title item-label">Tên</div>
                        <div class="item-input-wrap">
                          <input type="text" name="name" placeholder="" readonly/>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="item-content item-input">
                      <div class="item-inner">
                        <div class="item-title item-label">Số điện thoại</div>
                        <div class="item-input-wrap">
                          <input type="text" name="phone" placeholder="" readonly/>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="item-content item-input">
                      <div class="item-inner">
                        <div class="item-title item-label">Cơ sở</div>
                        <div class="item-input-wrap">
                          <input type="text" name="branch" placeholder="" readonly/>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="item-content item-input">
                      <div class="item-inner">
                        <div class="item-title item-label">Mật khẩu hiện thời</div>
                        <div class="item-input-wrap">
                          <input type="password" name="current-password"/>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="item-content item-input">
                      <div class="item-inner">
                        <div class="item-title item-label">Mật khẩu mới</div>
                        <div class="item-input-wrap">
                          <input type="password" name="new-password"/>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="item-content item-input">
                      <div class="item-inner">
                        <div class="item-title item-label">Xác nhận mật khẩu</div>
                        <div class="item-input-wrap">
                          <input type="password" name="confirm-password"/>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="item-content item-input">
                      <div class="item-inner text-align-center">
                        <a href="#" class="change-password-button">Thay đổi mật khẩu</a>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="login-popup">
      <div class="popup profile-popup">
        <div class="view">
          <div class="page">
            <div class="navbar">
              <div class="navbar-bg"></div>
              <div class="navbar-inner">
                <div class="title">Đăng nhập</div>
                <div class="right">
                  <a href="#" class="link popup-close"><i class="icon f7-icons">multiply</i></a>
                </div>
              </div>
            </div>
            <div class="page-content login-screen-content">
              <div class="login-screen-title">Hà Xinh - App nội bộ</div>
              <form>
                <div class="list">
                  <ul>
                    <li class="item-content item-input">
                      <div class="item-inner">
                        <div class="item-title item-label">Số điện thoại</div>
                        <div class="item-input-wrap">
                          <input type="text" name="phone" pattern="[0-9]*" placeholder="Số điện thoại của bạn" />
                        </div>
                      </div>
                    </li>
                    <li class="item-content item-input">
                      <div class="item-inner">
                        <div class="item-title item-label">Mật khẩu</div>
                        <div class="item-input-wrap">
                          <input type="password" name="password" placeholder="Mật khẩu" />
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="list">
                  <ul>
                    <li><a href="#" class="list-button login-button">Đăng Nhập</a></li>
                  </ul>
                  <div class="block-footer">
                    <p>Nhắc đến Phụ Kiện, Nhớ tới Hà Xinh</p>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
`
    }
    ;
});