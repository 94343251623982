/** @jsx $jsx */
import { $jsx } from 'framework7';
import $ from 'dom7';
import Base from '../js/base.js';
import Utils from '../js/utils.js';
import moment from 'moment';
import AutoNumeric from 'autonumeric';
var HistoryPopup = {
  init: function init(id) {
    var self = this;
  }
};
export default (function ($prop, ctx) {
  var id = Utils.str2Num(ctx.$f7route.params.id);
  var row = Base.historyItems.get(id);
  var items = row.items;
  var orderDate = moment(row.order_date).format(Base.dateTimeFormat);
  return function ($ctx) {
      var $ = $ctx.$;
      var $h = $ctx.$h;
      var $root = $ctx.$root;
      var $f7 = $ctx.$f7;
      var $f7route = $ctx.$f7route;
      var $f7router = $ctx.$f7router;
      var $theme = $ctx.$theme;
      var $update = $ctx.$update;
      var $store = $ctx.$store;

      return $h`
  <div class="popup" data-name="history-popup">
    <div class="navbar">
      <div class="navbar-bg"></div>
      <div class="navbar-inner">
        <div class="title">Đơn nhặt hàng ID-${id}</div>
        <div class="right">
          <a href="#" class="link popup-close"><i class="icon f7-icons">multiply</i></a>
        </div>
      </div>
    </div>
    <div class="page-content">
      <div class="block no-margin">
        Gửi ngày: ${orderDate}
      </div>
      <div class="data-table no-margin">
        <table>
          <thead>
            <tr>
              <th class="label-cell">Mã</th>
              <th class="label-only">Tên hàng</th>
              <th class="label-cell">SL</th>
            </tr>
          </thead>
          <tbody>
            ${items.map((item)=>$h`
              <tr>
                <td>${item.product_code}</td>
                <td>${item.name}</td>
                <td>${item.qty}<div>${item.note}</div></td>
              </tr>
            `)}
          </tbody>
        </table>
      </div>
    </div>
  </div>
`
    }
    ;
});