/** @jsx $jsx */
import { $jsx } from 'framework7';
export default (function (props, _ref) {
  var $ = _ref.$,
      $on = _ref.$on,
      $f7 = _ref.$f7;
  var page_name = 'huyhieu-home';
  var pn = "div[data-name=\"".concat(page_name, "\"]");
  return function ($ctx) {
      var $ = $ctx.$;
      var $h = $ctx.$h;
      var $root = $ctx.$root;
      var $f7 = $ctx.$f7;
      var $f7route = $ctx.$f7route;
      var $f7router = $ctx.$f7router;
      var $theme = $ctx.$theme;
      var $update = $ctx.$update;
      var $store = $ctx.$store;

      return $h`
<div class="page" data-name="${page_name}">
  <div class="page-content">
    <div class="block">
      <div class="row">
        <div class="col text-align-center">
          <div><a href="/huyhieu-wallet/"><i class="f7-icons icon" style="font-size: 60px;">checkmark_seal</i></a></div>
          <div>Điểm thưởng của tôi</div>
        </div>
      </div>
    </div>
    <div class="block">
      <div class="row">
        <div class="col text-align-center">
          <div><a href="/huyhieu-form/"><i class="f7-icons icon" style="font-size: 60px;">heart_circle</i></a></div>
          <div>Biểu mẫu khen thưởng</div>
        </div>
      </div>
    </div>
    <div class="block">
      <div class="row">
        <div class="col text-align-center">
          <div><a href="/huyhieu-transaction/"><i class="f7-icons icon" style="font-size: 60px;">arrow_right_arrow_left_square_fill</i></a></div>
          <div>Danh sách khen thưởng</div>
        </div>
      </div>
    </div>
  </div>
</div>
`
    }
    ;
});