import $ from 'dom7';
import moment from 'moment';
import Base from '../js/base.js';
import Utils from '../js/utils.js';

const Dayoff = {
  pn:'div[data-name="dayoff"]',
  init:async function(){
    let self = this;
    let f = moment().weekday(1).format(Base.dateFormat);
    let t = moment().weekday(7).format(Base.dateFormat);
    let $from = $(self.pn +' input[name="from_date"]');
    let $to = $(self.pn +' input[name="to_date"]');
    $from.val(f);
    $to.val(t);
    Base.app.calendar.create({inputEl:$from,dateFormat: 'd/m/yyyy'});
    Base.app.calendar.create({inputEl:$to,dateFormat: 'd/m/yyyy'});
    self.list();
    $from.change(function(){self.list()})
    $to.change(function(){self.list()})
  },
  fromDateVal:function(){
    return $(this.pn +' input[name="from_date"]').val();
  },
  toDateVal:function(){
    return $(this.pn +' input[name="to_date"]').val();
  },
  displayDates:function(){
    let self = this;
    let $table = $(self.pn +' .data-table tbody');
    $table.html('');
    let f = moment(self.fromDateVal(), Base.dateFormat);
    let t = moment(self.toDateVal(), Base.dateFormat);
    let diff = t.diff(f, 'days');

    if(diff < 0 || diff > 30)
      return false;

    for(let i=0; i<=diff; i++) {
      let d = t.clone().subtract(i,'days');
      let d1 = d.format(Base.dateFormat);
      let d2 = d.format('YYYY-MM-DD');
      let $tr = $(`<tr>
        <td>${d1}</td>
        <td data-date="${d2}">
          <div class="pending-and-approved"></div>
          <div class="denied"></div>
        </td>
      </tr>`);
      $table.append($tr);
    }
    return true;
  },
  list:async function(){
    let self = this;
    let valid = self.displayDates();

    if(!valid)
      return Utils.errorDialog('Xem thời gian không hợp lệ (Chỉ xem được từ 1-30 ngày)');

    let loading = Base.app.dialog.preloader('Đang tải...');
    let rs = await Base.api('Dayoff','list',{from_date:self.fromDateVal(), to_date:self.toDateVal()});
    loading.close();
    if(rs.rows.length == 0)
      return false;

    self.updateTable($(self.pn +' .data-table tbody'), rs);
  },
  updateTable:function($table, rs){
    let self = this;

    const rows = rs.rows || []; //Pending and approved
    const denied_rows = rs.denied_rows || []; //Denied

    let today = moment().format('YYYY-MM-DD');
    let now = moment(today);

    let makeEmp = function(item){
      let is_me = (Base.user.id == item.id_emp) ? 'color-orange' : '';
      let is_confirmed = '';
      if(parseInt(item.status) === 1) {
        is_confirmed = '';
      } else { //Base.initdata.dateoff_status_unconfirmed
        is_confirmed = '<div class="chip-media bg-orange-2">?</div>';
      }

      let $emp = $(`<div class="chip ${is_me}" data-id="${item.id}" data-wd="${item.working_date}">
                      ${is_confirmed}
                      <div class="chip-label ${(item.deleted)?'text-deleted':''}">${item.name} <span style="font-size:smaller">${moment(item.created_date).format('D/M H:mm')}</span></div>
                    </div>`);
      $emp.data('row', item);

      let selectedDate = moment(item.working_date);
      let diff = now.diff(selectedDate, 'days');
      if(diff < 0) {
        $emp.click(function(){
          self.remove($(this));
        })
      }
      return $emp;
    }
    if(rows.length > 0) {
      rows.forEach((item, i) => {
        let $emp = makeEmp(item);
        $table.find(`tr td[data-date="${item.working_date}"] .pending-and-approved`).append($emp);
      });
    }

    if(denied_rows.length > 0) {
      denied_rows.forEach((item, i) => {
        const $item = $(`<span style="font-size:smaller; border: 1px solid red; padding: 2px; margin: 3px; border-radius: 5px;">${item.name} ${(item.request_at) ?moment(item.request_at).format('D/M H:mm') : ''}</span>`);
        $table.find(`tr td[data-date="${item.working_date}"] .denied`).append($item);
      });
    }
  },
  remove:function($emp){
    let self = this;
    let data = $emp.data('row');
    if(Base.user.id != data.id_emp)
      return false;

    let remove = async function(){
      let loader = Base.app.dialog.preloader('Đang huỷ...');
      let rs = await Base.api('Dayoff','remove',{data});
      loader.close();
      $(self.pn +` .data-table tbody tr td div[data-id="${data.id}"][data-wd="${data.working_date}"]`).remove();
    }

    let message = 'Bạn có muốn huỷ lịch nghỉ này không?';
    if(data.status == 1)
      message = 'Lịch đã được xác nhận. Bạn chắc chắn muốn huỷ lịch?';

    Base.app.dialog.confirm(message,'Xác nhận', remove);
  },
}

export default Dayoff;
