import $ from 'dom7';
import moment from 'moment';
import Base from '../js/base.js';
import Utils from '../js/utils.js';

const Income = {
  pn:'div[data-name="income"]',
  row:null,
  init: function(){
    let self = this;
    self.row = null;
    let d = new Date();
    let year = d.getFullYear();
    let month = d.getMonth()+1;
    let $month = $(self.pn +' select[name="month"]');
    let $year = $(self.pn +' input[name="year"]');
    $month.val(month);
    $year.val(year);
    self.getdata();
    $month.change(function(){
      self.getdata();
    });
    $year.change(function(){
      self.getdata();
    });
  },
  getdata:async function(){
    let self = this;
    $(self.pn +' .data-table').addClass('display-none');
    let month = $(self.pn +' select[name="month"]').val();
    let year = $(self.pn +' input[name="year"]').val();
    self.selectedMonth = month;
    self.selectedYear = year;
    let loading = Base.app.dialog.preloader('Đang tải');
    let rs = await Base.api('Income','getdata', {month, year});
    loading.close();
    if(rs.row == null) {
      Utils.infoDialog('Không/Chưa có thông tin thu nhập của tháng này.');
      return false;
    }
    $(self.pn +' .data-table').removeClass('display-none');
    Income.row = rs.row;
    
    for(let field in Income.row){
      let v = Income.row[field];
      let num = Utils.str2Num(v);
      let str = Utils.num2Str(v);

      $(self.pn +` td[data-field="${field}"]`).text(str);
      if(num === 0) {
        $(self.pn +` td[data-field="${field}"]`).parent().addClass('display-none');
      } else {
        $(self.pn +` td[data-field="${field}"]`).parent().removeClass('display-none');
      }
    }
    let $customColorTd = $(self.pn +` td.custom-color`);
    let a = Utils.str2Num($customColorTd.text());
    if(a < 0) {
      $customColorTd.addClass('text-color-red');
      $customColorTd.removeClass('text-color-green');
    } else {
      $customColorTd.addClass('text-color-green');
      $customColorTd.removeClass('text-color-red');
    }
  },
}

export default Income;
