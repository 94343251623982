import Base from './base';

const Huyhieu = {
  myWallet:null,
  getWallet:async(month, callback = null)=>{
    Huyhieu.myWallet = null;
    const rs = await Base.api('Huyhieu', 'getWallet', {month});
    if(rs.rows.length > 0)
      Huyhieu.myWallet = rs;
    
    if(callback)
      callback();
  }
};
export default Huyhieu;
