/** @jsx $jsx */
import { $jsx } from 'framework7';
import $ from 'dom7';
import Base from '../js/base.js';
import Utils from '../js/utils.js';
import moment from 'moment';
import AutoNumeric from 'autonumeric';
var Draft = {
  pn: 'div[data-name="draft"]',
  init: function init() {
    var self = this;

    for (var key in localStorage) {
      var draftKey = key;

      if (draftKey.indexOf('draft_') != -1) {
        $(self.pn + ' .draft-list ul').append("\n        <li><a href=\"#\" class=\"draft-item\" key=\"".concat(draftKey, "\">").concat(draftKey.replace('draft_', ''), "</a></li>\n        "));
      }
    }

    $(self.pn + ' .draft-item').click(function () {
      var rowItem = this;
      var draftKey = $(rowItem).attr('key');
      Base.app.dialog.create({
        closeByBackdropClick: true,
        content: "\n          <div class=\"row\">\n            <div class=\"col\"><a href=\"#\" class=\"button button-fill open\">M\u1EDF</a></div>\n            <div class=\"col\"><a href=\"#\" class=\"button button-fill remove color-red\">Xo\xE1</a></div>\n          </div>\n        ",
        on: {
          open: function open(dialog) {
            $(dialog.el).find('.open').click(function () {
              dialog.close();
              Base.draftKey = draftKey;
              Base.app.views.main.router.navigate('/create/');
            });
            $(dialog.el).find('.remove').click(function () {
              dialog.close();
              localStorage.removeItem(draftKey);
              $(rowItem).remove();
            });
          }
        }
      }).open();
    });
  }
};
export default (function ($prop, ctx) {
  ctx.$on('pageInit', function () {
    Draft.init();
  });

  var profile = function profile() {
    Base.profile();
  };

  return function ($ctx) {
      var $ = $ctx.$;
      var $h = $ctx.$h;
      var $root = $ctx.$root;
      var $f7 = $ctx.$f7;
      var $f7route = $ctx.$f7route;
      var $f7router = $ctx.$f7router;
      var $theme = $ctx.$theme;
      var $update = $ctx.$update;
      var $store = $ctx.$store;

      return $h`
  <div class="page" data-name="draft">
    <div class="navbar">
      <div class="navbar-bg"></div>
      <div class="navbar-inner">
        <div class="left"></div>
        <div class="title sliding">Đơn nháp</div>
        <div class="right"><a href="#" @click=${profile}><i class="f7-icons icon">person_circle</i></a></div>
      </div>
    </div>
    <div class="page-content">
      <div class="list links-list draft-list no-margin">
        <ul>
        </ul>
      </div>
    </div>
  </div>
`
    }
    ;
});