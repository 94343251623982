/** @jsx $jsx */
import { $jsx } from 'framework7';
import Income from '../js/income.js';
export default (function (props, ctx) {
  ctx.$on('page:init', function () {
    Income.init();
  });
  return function ($ctx) {
      var $ = $ctx.$;
      var $h = $ctx.$h;
      var $root = $ctx.$root;
      var $f7 = $ctx.$f7;
      var $f7route = $ctx.$f7route;
      var $f7router = $ctx.$f7router;
      var $theme = $ctx.$theme;
      var $update = $ctx.$update;
      var $store = $ctx.$store;

      return $h`
<div class="page" data-name="income">
  <div class="page-content">
    <div class="list no-margin">
      <ul>
        <li class="item-content item-input">
          <div class="item-inner">
            <div class="item-input-wrap input-dropdown">
              <select name="month">
                <option value="1">Tháng 1</option>
                <option value="2">Tháng 2</option>
                <option value="3">Tháng 3</option>
                <option value="4">Tháng 4</option>
                <option value="5">Tháng 5</option>
                <option value="6">Tháng 6</option>
                <option value="7">Tháng 7</option>
                <option value="8">Tháng 8</option>
                <option value="9">Tháng 9</option>
                <option value="10">Tháng 10</option>
                <option value="11">Tháng 11</option>
                <option value="12">Tháng 12</option>
              </select>
            </div>
          </div>
          <div class="item-inner">
            <div class="item-input-wrap">
              <input type="text" pattern="[0-9]*" name="year"/>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div class="data-table card display-none">
      <table class="table-data">
        <tbody>
          <tr><td>Lương cứng</td><td class="numeric-cell" data-field="luong_cung">-</td><td></td></tr>
          <tr><td>Thưởng doanh số</td><td class="numeric-cell" data-field="thuong_doanh_so">-</td><td></td></tr>
          <tr><td>Thưởng đúng giờ</td><td class="numeric-cell" data-field="thuong_dung_gio">-</td><td></td></tr>
          <tr><td>Thưởng chuyên cần</td><td class="numeric-cell" data-field="thuong_chuyen_can">-</td><td></td></tr>
          <tr><td>Đủ công</td><td class="numeric-cell" data-field="luong_du_cong">-</td><td></td></tr>
          <tr><td>Thực công</td><td class="numeric-cell  text-color-green" data-field="luong_thuc_cong">-</td><td class="numeric-cell" data-field="so_ngay_cong">-</td></tr>
          <tr><td>Thưởng/Phạt</td><td class="numeric-cell custom-color" data-field="thuong_phat">-</td><td><a href="/income-bonus-popup/" class="button button-small detail">Chi tiết</a></td></tr>
          <tr><td>Các khoản khác</td><td class="numeric-cell text-color-green" data-field="cac_khoan_khac">-</td><td></td></tr>
          <tr class="numeric-cell text-color-orange text-bold"><td>TỔNG THU</td><td class="numeric-cell" data-field="tong_thu">-</td><td></td></tr>
          <tr><td>Đã ứng</td><td class="numeric-cell text-color-red" data-field="tam_ung">-</td><td></td></tr>
          <tr><td>Thuê nhà</td><td class="numeric-cell text-color-red" data-field="thue_nha">-</td><td></td></tr>
          <tr><td>Điện nước</td><td class="numeric-cell text-color-red" data-field="dien_nuoc">-</td><td></td></tr>
          <tr class="text-bold"><td>THỰC NHẬN</td><td class="numeric-cell" data-field="thuc_nhan">-</td><td></td></tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
`
    }
    ;
});