import HomePage from '../pages/home.f7.html';
import IncomePage from '../pages/income.f7.html';
import DayoffPage from '../pages/dayoff.f7.html';
import CrossReviewPage from '../pages/cross-review.f7.html';
import CreatePage from '../pages/create.f7.html';

import CustomerSearchPage from '../pages/customer-search.f7.html';
import CustomerSearchPopup from '../pages/customer-search-popup.f7.html';

import SearchPage from '../pages/search.f7.html';
import DraftPage from '../pages/draft.f7.html';
import LackPage from '../pages/lack.f7.html';
import HistoryPage from '../pages/history.f7.html';
import HistoryPopup from '../pages/history-popup.f7.html';
import IncomeBonusPopup from '../pages/income-bonus-popup.f7.html';
import DayoffPopup from '../pages/dayoff-popup.f7.html';
import CrossReviewPopup from '../pages/cross-review-popup.f7.html';
import FaqPage from '../pages/faq.f7.html';
import FaqAnswerPopup from '../pages/faq-answer-popup.f7.html';
import FaqQuestionPopup from '../pages/faq-question-popup.f7.html';
import NotificationPage from '../pages/notification.f7.html';
import ChuyenBanTonPage from '../pages/chuyen-ban-ton.f7.html';
import CommentPage from '../pages/comment.f7.html';
import ReportAccident from '../pages/report-accident.f7.html';

import ViPhamNoiQuy from '../pages/vipham-noiquy.f7.html';

import KhoThoc from '../pages/kho-thoc.f7.html';

import HuyhieuForm from '../pages/huyhieu-form.f7.html';
import HuyhieuHome from '../pages/huyhieu-home.f7.html';
import HuyhieuTransaction from '../pages/huyhieu-transaction.f7.html';
import HuyhieuWallet from '../pages/huyhieu-wallet.f7.html';

import UpdatePage from '../pages/update.f7.html';

import NotFoundPage from '../pages/404.f7.html';

var routes = [  
  {
    path: '/', name:'Lịch làm việc',
    component: HomePage,
    options: {
      transition: 'f7-fade',
    },
  },
  {
    path: '/kho-thoc/',
    component: KhoThoc,
    options: {
      transition: 'f7-fade',
    },
  },
  {
    path: '/vipham-noiquy/', name:'Nhắc nhở',
    component: ViPhamNoiQuy,
    options: {
      transition: 'f7-fade',
    },
  },
  {
    path: '/report-accident/', name:'Chấm công muộn',
    component: ReportAccident,
    options: {
      transition: 'f7-fade',
    },
  },
  {
    path: '/huyhieu-form/', name:'Biểu mẫu khen thưởng',
    component: HuyhieuForm,
    options: {
      transition: 'f7-fade',
    },
  },
  {
    path: '/huyhieu-home/', name:'Khen thưởng',
    component: HuyhieuHome,
    options: {
      transition: 'f7-fade',
    },
  },
  {
    path: '/huyhieu-transaction/', name:'Lịch sử khen thưởng',
    component: HuyhieuTransaction,
    options: {
      transition: 'f7-fade',
    },
  },
  {
    path: '/huyhieu-wallet/', name:'Điểm khen thưởng',
    component: HuyhieuWallet,
    options: {
      transition: 'f7-fade',
    },
  },
  {
    path: '/customer-search/', name:'Lịch sử khách hàng',
    component: CustomerSearchPage,
    options: {
      transition: 'f7-fade',
    },
  },
  {
    path: '/customer-search-popup/:code',
    popup:{
      component:CustomerSearchPopup,
    },
  },
  {
    path: '/update/', name:'Cập nhật',
    component: UpdatePage,
    options: {
      transition: 'f7-fade',
    },
  },
  {
    path: '/comment/', name:'Góp ý',
    component: CommentPage,
    options: {
      transition: 'f7-fade',
    },
  },
  {
    path: '/chuyen-ban-ton/', name:'Báo cáo chuyển/bán',
    component: ChuyenBanTonPage,
    options: {
      transition: 'f7-fade',
    },
  },
  {
    path: '/dayoff/', name:'Lịch nghỉ',
    component: DayoffPage,
    options: {
      transition: 'f7-fade',
    },
  },
  {
    path: '/income/', name:'Thu nhập',
    component: IncomePage,
    options: {
      transition: 'f7-fade',
    },
  },
  {
    path: '/cross-review/', name:'Đánh giá chéo',
    component: CrossReviewPage,
    options: {
      transition: 'f7-fade',
    },
  },
  {
    path: '/create/', name:'Tạo đơn nhặt',
    component: CreatePage,
    options: {
      transition: 'f7-fade',
    },
  },
  {
    path: '/search/', name:'Tìm kiếm',
    component: SearchPage,
    options: {
      transition: 'f7-fade',
    },
  },
  {
    path: '/draft/', name:'Đơn nháp',
    component: DraftPage,
    options: {
      transition: 'f7-fade',
    },
  },
  {
    path: '/lack/', name:'Thiếu hàng',
    component: LackPage,
    options: {
      transition: 'f7-fade',
    },
  },
  {
    path: '/history/', name:'Lịch sử đặt hàng',
    component: HistoryPage,
    options: {
      transition: 'f7-fade',
    },
  },
  {
    path: '/history-popup/:id',
    popup:{
      component:HistoryPopup,
    },
  },
  {
    path: '/income-bonus-popup/',
    popup:{
      component:IncomeBonusPopup,
    },
  },
  {
    path: '/dayoff-popup/',
    popup:{
      component:DayoffPopup,
    },
  },
  {
    path: '/cross-review-popup/:id/:name/:month',
    popup:{
      component:CrossReviewPopup,
    },
  },
  {
    path: '/faq/', name:'Cẩm nang bán hàng',
    component: FaqPage,
    options: {
      transition: 'f7-fade',
    },
  },
  {
    path: '/notification/', name:'Thông báo',
    component: NotificationPage,
    options: {
      transition: 'f7-fade',
    },
  },
  {
    path: '/faq-answer-popup/:qid',
    popup:{
      component:FaqAnswerPopup,
    },
  },
  {
    path: '/faq-question-popup/',
    popup:{
      component:FaqQuestionPopup,
    },
  },
  // {
  //   path: '/about/',
  //   component: AboutPage,
  // },
  // {
  //   path: '/form/',
  //   component: FormPage,
  // },
  //
  //
  // {
  //   path: '/dynamic-route/blog/:blogId/post/:postId/',
  //   component: DynamicRoutePage,
  // },
  // {
  //   path: '/request-and-load/user/:userId/',
  //   async: function ({ router, to, resolve }) {
  //     // App instance
  //     var app = router.app;
  //
  //     // Show Preloader
  //     app.preloader.show();
  //
  //     // User ID from request
  //     var userId = to.params.userId;
  //
  //     // Simulate Ajax Request
  //     setTimeout(function () {
  //       // We got user data from request
  //       var user = {
  //         firstName: 'Vladimir',
  //         lastName: 'Kharlampidi',
  //         about: 'Hello, i am creator of Framework7! Hope you like it!',
  //         links: [
  //           {
  //             title: 'Framework7 Website',
  //             url: 'http://framework7.io',
  //           },
  //           {
  //             title: 'Framework7 Forum',
  //             url: 'http://forum.framework7.io',
  //           },
  //         ]
  //       };
  //       // Hide Preloader
  //       app.preloader.hide();
  //
  //       // Resolve route to load page
  //       resolve(
  //         {
  //           component: RequestAndLoad,
  //         },
  //         {
  //           props: {
  //             user: user,
  //           }
  //         }
  //       );
  //     }, 1000);
  //   },
  // },
  {
    path: '(.*)',
    component: NotFoundPage,
  },
];

export default routes;
