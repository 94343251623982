/** @jsx $jsx */
import { $jsx } from 'framework7';
import $ from 'dom7';
import Base from '../js/base.js';
import Utils from '../js/utils.js';
import Income from '../js/income.js';
export default (function ($prop, ctx) {
  ctx.$on('popup:open', function () {
    if (Income.row == null) return false;
    var pn = 'div[data-name="income-bonus-popup"]';
    $(pn + ' table tbody').html('');
    var rows = Income.row.thuongphat_rows;
    var total = 0;
    if (rows.length == 0) return false;
    rows.forEach(function (item, i) {
      var sotien = item.so_tien * item.loai;
      var sotien_string = '';
      var color = 'text-color-green';

      if (item.loai > 0) {
        sotien_string = "+".concat(Utils.num2Str(sotien));
      } else {
        color = 'text-color-red';
        sotien_string = Utils.num2Str(sotien);
      }

      var $tr = $("<tr><td>".concat(item.ly_do, "</td><td class=\"numeric-cell ").concat(color, "\">").concat(sotien_string, "</td></tr>"));
      $(pn + ' table tbody').append($tr);
    });
    var color = 'text-color-green';
    var s = '+';

    if (Income.row.thuong_phat < 0) {
      color = 'text-color-red';
      s = '-';
    }

    var $td = $(pn + ' table tfoot tr td:nth-child(2)');
    $td.removeClass('text-color-green');
    $td.removeClass('text-color-red');
    $td.addClass(color).text(s + Utils.num2Str(Income.row.thuong_phat));
  });
  return function ($ctx) {
      var $ = $ctx.$;
      var $h = $ctx.$h;
      var $root = $ctx.$root;
      var $f7 = $ctx.$f7;
      var $f7route = $ctx.$f7route;
      var $f7router = $ctx.$f7router;
      var $theme = $ctx.$theme;
      var $update = $ctx.$update;
      var $store = $ctx.$store;

      return $h`
  <div class="popup" data-name="income-bonus-popup">
    <div class="navbar">
      <div class="navbar-bg"></div>
      <div class="navbar-inner">
        <div class="title">Chi tiết Thưởng/Phạt</div>
        <div class="right">
          <a href="#" class="link popup-close"><i class="icon f7-icons">multiply</i></a>
        </div>
      </div>
    </div>
    <div class="page-content no-padding">
      <div class="data-table">
        <table>
          <thead>
            <tr>
              <th class="label-cell">Mô tả</th>
              <th class="numeric-cell">Số tiền</th>
            </tr>
          </thead>
          <tbody>
          </tbody>
          <tfoot>
            <tr style="border-top:#333333 1px dotted;">
              <td class="numeric-cell text-bold">TỔNG</td>
              <td class="numeric-cell text-bold">0</td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  </div>
`
    }
    ;
});